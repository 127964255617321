<template>
  <div class="container">
    <div class="top">
      <div class="top_left">
        <div class="title"> {{topInfo.title}} </div>
        <div class="text"> {{topInfo.text}} </div>
      </div>
    </div>
    <div class="mid">
      <div class="mid_title"> {{midInfo.title}} </div>
      <div class="mid_box">
        <div class="mid_box_item" v-for="item in midInfo.list" :key="item">
          <img class="item_icon" :src="item.icon">
          <div class="item_title"> {{item.title}} </div>
          <div class="item_text"> {{item.text}} </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "product_cflz_hlwjhl",
    data(){
      return{
        topInfo:{
          title: '「互联网+」护理',
          text: '海南玉螺为全国实体医院搭建「互联网+」护理服务平台，可根据当地试点方案制定本地定制化护理服务平台。' +
            '海南玉螺依托移动互联网、人工智能等信息技术，以「线上预约、线下服务」的模式，将护理服务从院内延伸至社区、家庭，为出院患者或行动不便的特殊人群提供护理服务。',
        },
        midInfo:{
          title: '核心优势',
          list:[
            {
              icon: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_hlwjhl_glpt.png',
              title: '信息化管理平台',
              text: '利用移动互联网、物联网、智能呼叫、GPS定位技术、人脸识别技术等前沿信息化手段，为实体医院提供智能化、规范化的护理服务管理平台。',
            },
            {
              icon: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_hlwjhl_dtsj.png',
              title: '打通数据',
              text: '打通医院内部各科室数据，实现院内就诊到院外服务闭环。',
            },
            {
              icon: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_hlwjhl_gndz.png',
              title: '功能定制',
              text: '护理服务、健康知识、护理向导功能定制，满足大众多样化、多层次的健康需求。',
            },
            {
              icon: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_hlwjhl_aqbz.png',
              title: '安全保障',
              text: '患者身份认证、病历资料采集存储、护理人员定位追踪、个人隐私和信息安全保护、服务行为全程留痕追溯、工作量统计分析等。保障全程服务安全。',
            },
            {
              icon: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_hlwjhl_fwxw.png',
              title: '服务行为全程留痕可追溯',
              text: '患者身份认证、病历资料采集存储、护理人员定位追踪、个人隐私和信息安全保护、服务行为全程留痕追溯。',
            },
            {
              icon: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_hlwjhl_fxff.png',
              title: '风险防范',
              text: '为护士提供定位追踪系统，配备一键报警装置，购买责任险、医疗意外险和人身意外险等，切实保障护士执业安全和人身安全，有效防范和应对风险。',
            },
          ]
        }
      }
    },
    mounted(){
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
</script>

<style lang="stylus" scoped>
  .container{
    display flex
    flex-flow column
    background #F5F5F7
    font-family Alibaba PuHuiTi 2.0
    font-weight normal
    .top{
      width 100%
      height 957px
      background url("https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_product_hlwjhl_index.png") no-repeat
      background-size 100% 100%
      box-sizing border-box
      padding 0 140px
      display flex
      align-items center
      .top_left{
        display flex
        flex-flow column
        width 536px
        .title{
          font-size 56px
          line-height 67px
          color #343A40
        }
        .text{
          margin-top 30px
          font-size 20px
          line-height 34px
          color #6C757D
        }
      }
    }
    .mid{
      display flex
      flex-flow column
      box-sizing border-box
      padding 150px 140px 50px 140px
      .mid_title{
        font-size 56px
        line-height 67px
        color #343A40
        text-align center
        margin-bottom 100px
      }
      .mid_box{
        display flex
        flex-wrap wrap
        justify-content space-between
        .mid_box_item{
          display flex
          flex-flow column
          align-items center
          width 460px
          margin-bottom 150px
          .item_icon{
            width 140px
            height 140px
          }
          .item_title{
            font-size 40px
            line-height 56px
            color #343A40
            margin-top 40px
          }
          .item_text{
            font-size 20px
            line-height 28px
            color #6C757D
            margin-top 20px
          }
        }

      }
    }
  }
</style>
